import { t } from 'ttag';

const ValidateEmail = (name, data, onChange) => {

  if (! data.value) {
    onChange(name, {
      error:  t`Please enter an email`+'.',
      valid:  { invalid: true }
    });
    return false;
  }

  let regExp1 = new RegExp(/^[a-zA-Z0-9-_.@]+$/);
  if (! regExp1.test(data.value)) {
    onChange(name, {
      error:  t`Only letters, digits, dashes and dots`+'.',
      valid:  { invalid: true }
    });
    return false;
  }

  let regExp2 = new RegExp(/^(?:[^._-]+(?:.[^._-]+)*)@(?:[a-zA-Z0-9]+.)+(?:[.]{1,1}[a-zA-Z]{1,}){1,1}$/);
  if (! regExp2.test(data.value)) {
    onChange(name, {
      error:  t`Format "email@address.co"`+'.',
      valid:  { invalid: true }
    });
    return false;
  }

  let regExp3 = new RegExp(/^.{3,150}$/);
  if (! regExp3.test(data.value)) {
    onChange(name, {
      error:  t`Not too many characters`+'.',
      valid:  { invalid: true }
    });
    return false;
  }

  onChange(name, {
    error:  '',
    valid:  { valid: true }
  });
  return true;
}

export default ValidateEmail;
