import React from 'react';

const DiagonalLine = () => (
  <div className="diagonal-line">
    <svg version="1.1" viewBox="0 0 100 100" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0,0 L100,100" stroke="#000" strokeWidth="3"></path>
    </svg>
  </div>
);

export default DiagonalLine;
